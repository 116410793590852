<template>
  <div
    class="assignment-trip"
    :class="{
      'editing': isEditing,
      'selected': isSelected,
      'dispatched': isDispatched
    }">
    <div
      class="assignment-trip-header"
      @click="onClickTripHeader"
      @contextmenu="$emit('trip-contextmenu', $event, trip)">
      <div class="trip-id">
        {{ $t('assignment.rightPanel.assign.trip.id', { id: trip.id }) }}
      </div>
      <span
        v-if="isEditing || formattedLeaveBy"
        class="trip-header-separator">
        •
      </span>
      <span
        v-if="!isEditing && formattedLeaveBy"
        class="trip-leave-by">
        {{ formattedLeaveBy }}
      </span>
      <div class="trip-tasks-count">
        {{ $tc('assignment.rightPanel.assign.trip.tasksCount', incompleteTasks.length, { count: incompleteTasks.length }) }}
      </div>
      <template v-if="isEditing">
        <div
          v-if="!isDispatched"
          class="remove-trip"
          @click.stop="onRemoveTrip">
          <b-icon
            icon="trash"
            size="is-small"/>
        </div>
        <div
          v-if="tripEstimate"
          class="trip-tasks-estimate">
          <b>Estimated:</b> {{ tripEstimate.distance | units('m', 'km', false) | round(2) }} km • {{ tripEstimate.time | units('s', 'min', false) | round(0) }} mins
        </div>
        <div v-if="isDispatched">
          {{ formattedStartedAt }}
        </div>
        <leave-by-selector
          v-else
          :selectedOption.sync="selectedLeaveLeadTime"
          :earliestStartTime="earliestStartTime"
          @click.native.stop/>
      </template>
    </div>
    <component
      :is="isEditing && !isDispatched ? 'vuedraggable' : 'div'"
      v-model="sortedTasks"
      group="assigning"
      class="assignment-trip-tasks"
      :class="{'dragging': isDragging}"
      @start="isDragging = true"
      @end="isDragging = false">
      <assignment-task
        v-for="task in sortedTasks"
        :key="`assignment_trip_orders_${task.id}`"
        :task="task"
        :removable="isEditing && !isDispatched"
        :statusVisible="statusVisible"
        @task-contextmenu="(event, task) => $emit('task-contextmenu', event, task)"
        @show-strong-confirmation="showStrongConfirmation"/>
      <div
        v-if="sortedTasks.length === 0"
        class="assignment-trip-tasks-completed">
        {{ $t('assignment.rightPanel.assign.trip.allTasksCompleted') }}
      </div>
    </component>
  </div>
</template>

<script>
import { ONE_HOUR, ONE_MINUTE, TASK_STATES } from '@js/constants'
import { mapActions, mapGetters } from 'vuex'
import { formatTimeFromDate } from '@js/utils'
import { gmapApi } from 'vue2-google-maps'
import { isTripDispatched } from '@js/trip-utils'

const AssignmentTask = () => import('@components/AssignmentTask')
const LeaveBySelector = () => import('./LeaveBySelector')
const Vuedraggable = () => import('vuedraggable')

export default {
  name: 'assignment-trip',
  components: {
    AssignmentTask,
    LeaveBySelector,
    Vuedraggable
  },
  filters: {
    round: function(value, decimals) {
      if (!value) {
        value = 0
      }

      if (!decimals) {
        decimals = 0
      }

      value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
      return value
    }
  },
  props: {
    trip: {
      type: Object,
      required: true
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    statusVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDragging: false,
      selectedLeaveLeadTime: null,
      tripEstimate: null,
      lastRoutedOn: null,
      directionsRetry: 0,
      googleMapsRouteEnabled: true
    }
  },
  computed: {
    ...mapGetters('hub', [
      'getHub'
    ]),
    ...mapGetters('trip', [
      'getCachedTrip'
    ]),
    incompleteTasks() {
      return this.trip.tasks.filter(task => task.state !== TASK_STATES.COMPLETED)
    },
    google: gmapApi,
    sortedTasks: {
      get() {
        if (!this.isEditing) {
          return this.incompleteTasks.concat().sort((a, b) => {
            return a.priority - b.priority
          })
        }
        return this.trip.tasks
      },
      set(value) {
        this.setTripTasks({
          tripId: this.trip.id,
          tasks: value
        })
      }
    },
    earliestStartTime() {
      if (this.sortedTasks.length > 0) {
        return Math.min(...this.sortedTasks.map(task => task.startTime))
      }
      return null
    },
    leaveBy() {
      if (this.earliestStartTime) {
        const seconds = this.earliestStartTime - this.selectedLeaveLeadTime * ONE_MINUTE
        const date = new Date(this.sortedTasks[0].servingDate).setHours(0, 0)
        return new Date(date + seconds * 1000)
      }
      return null
    },
    formattedLeaveBy() {
      if (this.leaveBy) {
        return this.$t('assignment.rightPanel.assign.leaveBy.title', { time: formatTimeFromDate(this.leaveBy) })
      }
      return null
    },
    formattedStartedAt() {
      if (this.trip.startedAt) {
        return this.$t('assignment.rightPanel.assign.trip.startedAt', { time: formatTimeFromDate(this.trip.startedAt) })
      }
      return null
    },
    isDispatched() {
      return isTripDispatched(this.trip)
    }
  },
  watch: {
    leaveBy() {
      // update only when editing, and leaveBy changed
      if (this.isEditing && this.leaveBy && this.leaveBy.getTime() !== new Date(this.trip.leaveBy).getTime()) {
        this.updateTripLeaveBy({
          tripId: this.trip.id,
          isNew: this.trip.isNew,
          leaveBy: this.leaveBy,
          leaveLeadTime: this.selectedLeaveLeadTime
        })
      }
    },
    sortedTasks() {
      this.tripEstimate = null
    }
  },
  updated() {
    if (this.isEditing) {
      this.getDistanceAndTime()
    }
  },
  created() {
    this.selectedLeaveLeadTime = this.trip.leaveLeadTime || this.getLeadTime(this.trip.leaveBy)
    if (this.isEditing) {
      this.getDistanceAndTime()
    }
  },
  methods: {
    ...mapActions('trip', [
      'removeTrip',
      'selectTrip',
      'setTripTasks',
      'updateTripLeaveBy',
      'setTripPolyline',
      'setCachedTrip'
    ]),
    getLeadTime(leaveBy) {
      const leaveByDate = new Date(leaveBy)
      const hour = leaveByDate.getHours()
      const minute = leaveByDate.getMinutes()
      const leaveByTime = hour * ONE_HOUR + minute * ONE_MINUTE
      return (this.earliestStartTime - leaveByTime) / ONE_MINUTE
    },
    directionsService() {
      return new this.google.maps.DirectionsService()
    },
    getDistanceAndTime() {
      if (!this.googleMapsRouteEnabled || this.tripEstimate || this.lastRoutedOn === this.sortedTasks) {
        return
      }

      const locations = this.sortedTasks.map((task) => {
        return new this.google.maps.LatLng(task.latitude, task.longitude)
      })
      const destination = locations.pop()
      const waypoints = locations.map((location) => ({ location, stopover: true }))

      const tripFingerprint = this.sortedTasks.map((task) => (task.id)).join('-')
      const cachedTripDistanceResponse = this.getCachedTrip(tripFingerprint)

      if (cachedTripDistanceResponse) {
        this.setDistanceAndTime({ response: cachedTripDistanceResponse, waypoints })
      } else {
        const queryHubId = parseInt(this.$route.query.hub)
        const hub = (this.getHub(queryHubId))
        const service = this.directionsService()
        const origin = new this.google.maps.LatLng(hub.latitude, hub.longitude)

        try {
          service.route({
            origin,
            destination,
            travelMode: this.google.maps.TravelMode.DRIVING,
            unitSystem: this.google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,
            waypoints
          },
          (response, status) => {
            if (status !== 'OK') {
              alert('Error was: ' + status)
            } else {
              this.setCachedTrip({ tripFingerprint, response })
              this.setDistanceAndTime({ response, waypoints })
            }
          })
        } catch(error) {
          this.directionsRetry += 1
          if (this.directionsRetry > 4) {
            alert('Could not get directions from Google, try again shortly or report on #tech-bugs')
          } else {
            console.log(error)
            setTimeout(this.getDistanceAndTime, 500 * this.directionsRetry * this.directionsRetry) // exponential back off
          }
        }
      }
    },
    setDistanceAndTime({ response, waypoints }) {
      let distance = 0
      let time = 0

      response.routes.forEach((route) => {
        route.legs.forEach((leg) => {
          distance += leg.distance.value
          time += leg.duration.value
        })
      })

      time += waypoints.length * 300 // add 5 minutes to each waypoint

      const polylinePaths = this.google.maps.geometry.encoding.decodePath(
        response.routes[0].overview_polyline
      )
      this.setTripPolyline(polylinePaths)

      this.tripEstimate = {
        distance,
        time
      }
    },
    onClickTripHeader() {
      this.selectTrip(this.trip.id)
    },
    onRemoveTrip() {
      if (this.trip.tasks.some(task => task.externalLogisticsId && task.externalLogisticsType === 'lalamove')) {
        return this.showStrongConfirmation({
          message: this.$t('strongConfirmationDialog.cancelTripWithPickupp.lalamoveMessage'),
          onConfirm: () => this.showRemoveTripConfirmation()
        })
      } else if (this.trip.tasks.some(task => task.externalLogisticsId && task.externalLogisticsType === 'pickupp')) {
        return this.showStrongConfirmation({
          message: this.$t('strongConfirmationDialog.cancelTripWithPickupp.message'),
          onConfirm: () => this.showRemoveTripConfirmation()
        })
      }
      this.showRemoveTripConfirmation()
    },
    showStrongConfirmation(data) {
      this.$emit('show-strong-confirmation', data)
    },
    showRemoveTripConfirmation() {
      this.$buefy.dialog.confirm({
        title: this.$t('assignment.rightPanel.assign.trip.remove.title'),
        message: this.$t('assignment.rightPanel.assign.trip.remove.message'),
        confirmText: this.$t('assignment.rightPanel.assign.trip.remove.confirm'),
        cancelText: this.$t('assignment.rightPanel.assign.trip.remove.cancel'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.removeTrip(this.trip)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.assignment-trip {
  &:not(.editing) {
    .assignment-trip-header {
      display: flex;
      align-items: center;

      .trip-id,
      .trip-header-separator {
        margin-right: $space-xxs;
      }

      > :nth-last-child(2) {
        flex-grow: 1;
      }
    }
  }

  &.editing {
    &.selected {
      .assignment-trip-header {
        border-color: $black;
      }
    }

    &.dispatched {
      opacity: 0.5;
    }

    .assignment-trip-header {
      color: $grey-darker;
      background-color: $grey-lighter;

      .trip-tasks-estimate {
        margin: $space-xxs 0;
      }

      .trip-id,
      .trip-header-separator,
      .trip-tasks-count {
        display: inline-block;
        color: $grey-darker;
        @extend %small;

        &:not(:last-child) {
          margin-right: $space-xxs;
        }
      }

      .leave-by-selector {
        color: $black;
      }

      .remove-trip {
        float: right;
        cursor: pointer;
      }
    }
  }

  .assignment-trip-header {
    padding: $space-xs;
    color: $white;
    background-color: $grey-darkest;
    @extend %small;

    .trip-header-separator,
    .trip-leave-by,
    .trip-tasks-count {
      color: $grey;
    }
  }

  .assignment-trip-tasks {
    &.dragging {
      .assignment-task {
        background-color: transparent;
      }
    }

    .assignment-task {
      &[draggable="true"] {
        background-color: rgba($yellow, 0.25);
      }

      &::v-deep {
        &:not(:last-child) {
          .task-map-marker {
            border-bottom: 1px solid $grey-dark;
          }
        }
      }
    }

    .assignment-trip-tasks-completed {
      padding: $space-xs;
      font-weight: bold;
      color: $success-dark;
    }
  }
}
</style>
